import React from 'react';
import data from './data.json';

var whp = "https://wa.me/56978451395?text=Hola quiero comprar una cámara "
const Productos = () => {
  return (
    <div>
      {data.map((item) => 
      (
        <div className='container flex flex-row m-6'>
          <div className='pb-4 rounded-2xl mx-auto w-96 border-spacing-60 border-8 border-gray-600' >
            <img src={item.fotoPRoducto} className='round-3xl py-1 h-80 mx-auto' alt={item.nomProducto} />

            <div className='mx-auto px-7 justify-self-stretch text-center font-mono pb-4'>{item.descProducto}</div>
            <div className='pt-2 mx-auto w-80 bg-gray-600 h-14 rounded-lg'>
              <a aria-label="Nexovista-WhatsApp"
                href={whp+item.nomProducto}>
                <h1 className='text-2xl text-center font-bold text-white'>
                  <span className='text-2xl font-sans font-normal' >{'Precio(Clp): '}
                  </span>
                  {item.precioProducto}
                </h1>
              </a>
            </div>
          </div>
        </div>
      )
      )
      }
    </div>
  )
}
//"https://wa.me/56978451395?text=Hola quiero comprar una cámara"
export default Productos
