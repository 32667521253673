import Portada from "./components/portada/portada.tsx";
import Productos from "./components/productos/productos.tsx";
function App() {
  return (
    <div >
      <Portada />
      <Productos />
    </div>
  );
}
export default App;
