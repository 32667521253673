import React from 'react';
import imgPortada from '../../portadaNexovista.png';
import wa from '../../WhatsAppButtonWhiteMedium.png'

const Portada = () => {
  return (
    <div className='container flex flex-row m-6'>
      <div className='pb-4 rounded-2xl mx-auto w-96 ' >
        <img src={imgPortada} className='round-3xl py-1 mx-auto w-96' alt={'Camaras-Nexovista'} />
        <div className='mx-auto px-7 justify-self-stretch text-center font-mono pb-4'>{ }</div>
          <a href='https://wa.me/56978451395?text=Hola quiero comprar una cámara '>
            <img src='./whatsapp.png' alt='enviar-mensaje' className=' animate-pulse mx-auto w-64 py-2 rounded-3xl '></img>
          </a>
          <div className='mx-auto text-center'>
            <a aria-label="Nexovista-WhatsApp" href="https://wa.me/56978451395?text=Hola quiero comprar una cámara ">
              <img className='mx-auto w-72 ' alt="Comunicate conmigo por Whatsapp" src={wa}></img>
            </a>
          </div>
      </div>
    </div>





    // <div className='max-w-96 min-w-max'>
    //   <img src={imgPortada} className='m-8 max-w-64' alt="portada-nexovista" />
    // <a href='https://wa.me/56978451395?text=Hola quiero comprar una cámara '>
    // <img src='./whatsapp.png' alt='enviar-mensaje' className=' animate-pulse mx-auto w-64 py-2 px-0 rounded-3xl '></img>
    // </a>
    //   <div className='mx-auto text-center'>
    //     <a aria-label="Nexovista-WhatsApp" href="https://wa.me/56978451395?text=Hola quiero comprar una cámara ">
    //       <img className='mx-auto w-72 ' alt="Comunicate conmigo por Whatsapp" src={wa}></img>
    //     </a>
    //   </div>
    // </div>
  )
}
export default Portada;
